import {Image} from 'react-bootstrap';

function DocType({setDocType, docType}) {
  const onHandleChange = (e) => {
    console.log({ data: e.target.value})
    setDocType(e.target.value)
  }
  return (
    <div className="doc-wrap">
      <label htmlFor="doc-05">
        <input type="radio" name="document" id="doc-05" value='invoice' onChange={onHandleChange} checked={docType === 'invoice'}/>
        <span><Image src="./images/Invoice.png" /></span>
        <p>Invoices</p>
      </label>
      <label htmlFor="doc-01">
        <input type="radio" name="document" id="doc-01" value='kyc' onChange={onHandleChange} checked={docType === 'kyc'}/>
        <span><Image src="./images/Receipt.png" /></span>
        <p>KYC</p>
      </label>
    </div>
  );
}

export default DocType;
